<template>
  <div class="pd20x flex1">
    <SendGoods :visible="openClasses" @close="openClasses = false" detail_type="1" :options="options" :data="currentItem" :edit="editClass" @update="getData"></SendGoods>
    <div class="contentBox h100pct ofA">
      <div class="filter" style="padding:10px 20px">
        <div>订单数量（{{ pagination.total }}个） </div>
        <div>
          <label>搜索：</label>
          <a-input-search v-model="filter.keywords" placeholder="搜索订单商品标题" style="width: 200px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
          <a-button type="primary" @click="openClasses = true; currentItem = null; editClass = false">赠送商品</a-button>
        </div>
      </div>
      <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :rowKey="'id'"
          @change="changePage"
      >
        <template slot="index" slot-scope="text, record">
          {{record.order_no}}
        </template>
        <template slot="status" slot-scope="text">
          {{ text | statusFilter }}
        </template>
        <template slot="create_time" slot-scope="text">
          {{formatDate(text*1000)}}
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type == 1">虚拟产品</span>
          <span v-if="record.type == 2">实物产品</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <i class="z-icon editB" @click="routerChange('详情', '/exam/theoryDetail', {id:record.id})"></i>
          <i class="z-icon delete" @click="deleteItem(record.id)"></i>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [{
  title: '订单号',
  scopedSlots: { customRender: 'index' },
  align:'center'
},
  {
    title: '商品标题',
    dataIndex: 'title',
    align:'center'
  }, {
    title: '商品类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
    align:'center'
  }, {
    title: '实际支付',
    dataIndex: 'price',
    align:'center',
  }, {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align:'center'
  },  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  }
  // , {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' },
  // }
];
const statusList = {
  0 : '代付款',
  1 : '待发货',
  2 : '待收货',
  3 : '已完成',
  5 : '已取消'
};

import SendGoods from '@/components/exam/sendGoods'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
import {formatDate} from '@/libs/moment'
export default {
  name: 'ordersList',
  components: { SendGoods },
  mixins: [routerlink],
  data(){
    return{
      options: [],
      columns,
      filter:{
        cate_id: 0,
        keywords:''
      },
      pagination:{
        page: 1,
        size: '15',
        pageSize: 15
      },
      data: [],
      areaval: '',
      openClasses: false,
      currentItem:'',
      editClass:false
    }
  },
  filters: {
    statusFilter (status) {
      return statusList[status]
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.getData()
    })
  },
  methods:{
    formatDate,
    getData(){
      let request = {...this.filter,...this.pagination,detail_type:1}
      if(this.filter.cate_id == 0){
        request.cate_id = null
      }
      http.get_orders_list(request).then(res=>{
        const pagination = { ...this.pagination }
        pagination.total = res.total;
        this.data = res.data
        this.pagination = pagination;

      }).catch(error=>{
        console.log(error)
      })
    },
    deleteItem(id){
      let _this = this
      this.$confirm({
        title: '是否确定删除该套卷信息?',
        centered: true,
        onOk() {
          http.del_crouse_bag({id}).then(res=>{
            let {page, pagesize, total} = _this.pagination
            if(page>1 && Number(page-1)* 15 == total - 1){
              _this.pagination.page = Number(page-1)
            }
            _this.getData()
          }).catch(error=>{
            console.log(error)
          })
        },
      });
    },
    changePage({current}){
      this.pagination.page = current
      this.getData()
    },
    hideModal(){

    },
    onChange(){

    },
  }
}
</script>
<style lang="less" scoped>
.imgWrap{
  width: 120px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;
  img{
    width: 100%;
  }
}
</style>