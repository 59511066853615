<template>
  <a-drawer
      :title="title"
      :visible="visible"
      :placement="'top'"
      @close="$emit('close')"
      :height="'100%'"
      :wrapClassName="'addClasses'"
  >
    <div class="content">
      <a-form
          :form="form"
          v-bind="formItemLayout"
      >
        <a-form-item label="赠送用户">
          <a-input
              v-decorator="['userIds', { initialValue: formData.userIds, rules: [{ required: true, message: '请输入赠送用户ID' }] }]"
          />
        </a-form-item>
        <a-form-item label="商品ID">
          <a-input
              v-decorator="['goodsid', { initialValue: formData.goodsid, rules: [{ required: true, message: '请输入商品id' }] }]"
          />
        </a-form-item>
<!--        <a-form-item label="可用次数">-->
<!--          <a-input v-decorator="['valid_time', { initialValue: formData.valid_time, rules: [{ required: true, message: '请填写套卷内可模拟的次数' }] }]" style="width:160px;margin-right:10px"></a-input>次-->
<!--        </a-form-item>-->
        <a-form-item label="实际付款">
          <a-input
              v-decorator="['price', { initialValue: formData.price, rules: [{ required: true, message: '请输入商品id' }] }]"
          />
        </a-form-item>
      </a-form>
      <div style="text-align:right;padding-top:30px;padding-right:20%">
        <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
        <a-button type="primary"  style="width:100px" @click="submit">立即赠送</a-button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { uploadImg } from '@/libs/api'
import * as http from '@/libs/examapi'
export default {
  name: 'addClasses',
  props: ['visible', 'options', 'data', 'edit', 'detail_type'],
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  data(){
    return{
      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
      },
      showP:false,
      loading: false,
      subjectList: [],
      ProfessList:[],
      detailUrl:'',
      title:'添加套卷',
      detailfileImg:[],
      detail_bg_url:'',
      formData:{
        userIds:'', goodsid:'', price:''
      }
    }
  },
  watch:{
    visible(val){
      if(!val){
        this.reset()
      }else{
        if(this.edit){
          Object.assign(this.formData, this.data);
          this.title = '编辑套卷'
          this.detail_bg_url = this.data.detail_bg_url
          this.detail_type = this.data.detail_type
          setTimeout(()=>{
            this.form.setFieldsValue({'fileImg':[{uid:-1, name: 'image.png',status: 'done', url: this.data.img}]})
            this.form.setFieldsValue({'detailfileImg':[{uid:2, name: 'imageBg.png',status: 'done', url: this.data.detail_bg_url}]})
          })
        }else{
          this.title = '新增套卷'
        }
      }
    }
  },
  methods:{
    reset(){
      this.form.resetFields()
      this.detail_bg_url = ''
      this.loading = false
      this.detailUrl = ''
      this.showP = false
      this.formData = {
        userIds:'', goodsid:'', price:''
      }
    },
    customRequestDetail(data){
      let formData = new FormData()
      formData.append('file',data.file)
      uploadImg(formData).then(res=>{
        if(res){
          this.detailUrl=res.fileUrl
        }
      },error=>{
        console.log(error)
      })

    },
    customRequestDetailBg(data){
      let formData = new FormData()
      formData.append('file',data.file)
      uploadImg(formData).then(res=>{
        if(res){
          this.detail_bg_url=res.fileUrl
        }
      },error=>{
        console.log(error)
      })
    },
    submit(){
      this.form.validateFields((err, values) => {
        if(err) return;
        let request = this._.cloneDeep(values)
        if(this.edit){
          request.id = this.data.id
          http.edit_crouse_bag(request).then(res=>{
            this.$emit('close')
            this.$emit('update')
          }).catch(error=>{
            console.log(error)
          })
        }else{
          http.send_user_goods(request).then(res=>{
            this.$emit('close')
            this.$emit('update')
          }).catch(error=>{
            console.log(error)
          })
        }
      })
    },
    vaildatorPrice(rule, value, callback){
    }
  }
}
</script>
<style lang="less" scoped>
.sTable{
  width: 420px;
  border: 1px solid #eee;
  .th{
    background: #eeeeee;
    height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    >div{
      flex: 1;
    }
  }
  .tbody{
    padding: 20px 10px;
    display: flex;
    align-items: center;
    >div{
      flex: 1;
    }
  }
}
</style>